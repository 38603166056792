import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import Layout from '@components/common/Layouts/Setup';
import { useTheme } from '@mui/styles';
import { RouteComponentProps, useLocation } from '@reach/router';
import { useSnackbar } from '@components/common/Snackbar';
import { Card, CardModes, CardSteps } from '@models/card';
import { makeGetRequest, makePostRequest, makePutRequest } from '@helpers/requests';
import { CARD, EXTERNAL_URLS, LINK_HUBS } from '@helpers/api';
import { Formik } from 'formik';
import { User } from '@models/user';
import * as Yup from 'yup';
import Loader from '@components/common/Loader';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import { navigateParams, validateUrl } from '@helpers/common';
import usePrevious from '@helpers/hooks/usePrevious';
import Welcome from '@views/Setup/Welcome';
import Name from '@views/Setup/Name';
import { Box } from '@mui/material';
import Mode from '@views/Setup/Mode';
import Customise from '@views/Setup/Customise';
import Complete from '@views/Setup/Complete';
import { LinkHubValidation } from '@components/Dashboard/LinkHubs/LinkHub/Editor';

export const SetupCardNameValidation = Yup.string().required().label('Name');

export const SetupCardModeValidation = Yup.string()
	.oneOf(['linkHub', 'vCard', 'externalUrl'])
	.required()
	.label('Mode');

export const SetupCardExternalUrlValidation = Yup.mixed()
	.nullable()
	.when('mode', {
		is: 'externalUrl',
		then: Yup.object().shape({
			url: Yup.string()
				.test('is-url', 'Enter a valid URL', (value) => validateUrl(value))
				.required()
				.label('URL'),
		}),
	});

export const SetupCardVCardValidation = Yup.mixed()
	.nullable()
	.when('mode', {
		is: 'vCard',
		then: Yup.object().shape({
			photo: Yup.string().nullable().label('Avatar'),
			organization: Yup.string().label('Organization'),
			birthday: Yup.date().nullable().label('Birthday'),
			firstName: Yup.string().required().label('First name'),
			lastName: Yup.string().required().label('Last name'),
			jobTitle: Yup.string().label('Job title'),
			title: Yup.string().label('Title'),
			email: Yup.string().email().label('Email'),
			phoneNumbers: Yup.array().of(
				Yup.object().shape({
					type: Yup.string().oneOf(['home', 'work']).label('Phone number type'),
					callingCode: Yup.string().label('Calling code'),
					number: Yup.string().label('Phone number'),
				})
			),
			addresses: Yup.array().of(
				Yup.object().shape({
					country: Yup.string().label('Country'),
					city: Yup.string().label('City'),
					postcode: Yup.string().label('Postcode'),
					streetOne: Yup.string().label('Street One'),
					streetTwo: Yup.string().label('Street Two'),
				})
			),
		}),
	});

export const SetupCardLinkHubValidation = Yup.mixed().nullable().when('mode', {
	is: 'linkHub',
	then: LinkHubValidation,
});

export type SetupInitialValues = {
	name: string;
	mode: CardModes | '';
	externalUrlId: string | null;
	vCardId: string | null;
	linkHubId: string | null;
};

const Setup: React.FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
	const location: any = useLocation();
	const params: any = queryString.parse(location.search);
	const step: CardSteps | null = params.step;
	const steps = ['welcome', 'name', 'mode', 'customise', 'complete'];
	const theme = useTheme();
	const [openSnackbar] = useSnackbar();
	const [show, setShow] = useState(true);
	const [user, setUser] = useState<User | null>(null);
	const [card, setCard] = useState<Card | null>(null);
	const prevCard = usePrevious(card);
	const [loading, setLoading] = useState<boolean>(true);

	const handleInitialStep = async () => {
		const { name, mode } = card || {};

		let initialStep = (!!name && mode === 'setup' && 'mode') || 'welcome';
		// if parameterised step exists and is before the determined step use that instead
		if (
			!!step &&
			initialStep !== 'complete' &&
			steps.indexOf(step) < steps.indexOf(initialStep)
		) {
			initialStep = step;
		}

		await navigateParams({
			...params,
			step: initialStep,
		});

		// loading is finished now the step has been finalised
		setLoading(false);
	};

	useEffect(() => {
		// checkout has been created/retrieved
		console.log({ prevCard, card });
		if (!prevCard && !!card) {
			(async () => {
				await handleInitialStep();
			})();
		}
	}, [card]);

	useEffect(() => {
		(async () => {
			try {
				const { data: cardData } = await makeGetRequest(CARD(id));

				if (cardData?.mode !== 'setup') {
					openSnackbar('Your card has already been setup.');
					navigate(`/dashboard/card/${cardData?._id}`);
					return;
				}

				setCard(cardData);
				setLoading(false);
			} catch (error) {
				if (error !== 'cancelled') {
					setLoading(false);
					openSnackbar(
						error?.errorMessage ?? `An error occurred attempting to load your card.`,
						'error'
					);
				}
			}
		})();
	}, []);

	const handleSetupSave = async (values) => {
		try {
			const { data: cardData } = await makePutRequest(CARD(card?._id), {
				name: values?.name || (card?.name ?? undefined),
				mode: values?.mode || 'setup',
				linkHubId: values?.linkHubId || undefined,
				vCardId: values?.vCardId || undefined,
				externalUrlId: values?.externalUrlId || undefined,
			});

			setCard(cardData);
		} catch (error) {
			console.log(2, 'error', error);
			if (error !== 'cancelled') {
				openSnackbar(
					error?.errorMessage ?? 'An error occurred attempting to save your card.',
					'error'
				);
			}
		}
	};

	const handleSubmit = async (values) => {
		try {
			console.log('handleSubmit');
			await handleSetupSave(values);
			await navigateParams({ ...params, step: 'complete' });
		} catch (error) {
			openSnackbar(error?.errorMessage ?? `Unable to update your card.`, 'error');
		}
	};

	if (loading) {
		return <Loader />;
	}

	const initialValues: SetupInitialValues = {
		name: card?.name ?? '',
		mode: '',
		externalUrlId: null,
		vCardId: null,
		linkHubId: null,
	};

	return (
		<Formik
			validationSchema={Yup.object().shape({
				name: SetupCardNameValidation,
				mode: SetupCardModeValidation,
				externalUrlId: Yup.mixed().when('mode', {
					is: 'externalUrl',
					then: Yup.string().required().label('External URL'),
				}),
				vCardId: Yup.mixed().when('mode', {
					is: 'vCard',
					then: Yup.string().required().label('Contact Card'),
				}),
				linkHubId: Yup.mixed().when('mode', {
					is: 'linkHub',
					then: Yup.string().required().label('Link Hub'),
				}),
			})}
			onSubmit={handleSubmit}
			enableReinitialize
			initialValues={initialValues}
		>
			{({ setFieldValue, handleSubmit, values, touched, errors }) => {
				console.log({ errors });

				const handleNextStep = async (values?: any) => {
					try {
						if (step === 'welcome') {
							await navigateParams({
								...params,
								step: 'name',
							});
						}

						if (step === 'name') {
							setFieldValue('name', values.name);
							await handleSetupSave(values);
							await navigateParams({ ...params, step: 'mode' });
						}

						if (step === 'mode') {
							setFieldValue('mode', values.mode);
							await navigateParams({ ...params, step: 'customise' });
						}

						if (step === 'customise') {
							setFieldValue('vCardId', values.vCardId);
							setFieldValue('externalUrlId', values.externalUrlId);
							setFieldValue('linkHubId', values.linkHubId);

							await handleSubmit();
						}
					} catch (error) {
						if (error !== 'cancelled') {
							openSnackbar(
								error?.errorMessage ??
									'An error occurred attempting to save your checkout changes.'
							);
						}
					}
				};

				const handlePrevStep = () => {
					if (step === 'name') {
						navigateParams({ ...params, step: 'welcome' });
					}

					if (step === 'mode') {
						navigateParams({ ...params, step: 'name' });
					}

					if (step === 'customise') {
						navigateParams({ ...params, step: 'mode' });
					}

					if (step === 'complete') {
						navigateParams({ ...params, step: 'customise' });
					}
				};

				if (loading || !card) return <Loader />;

				return (
					<Box sx={{ minHeight: '100%', display: 'flex', flexGrow: 1 }}>
						{step === 'welcome' && <Welcome card={card} onContinue={handleNextStep} />}
						{step === 'name' && <Name card={card} onSubmit={handleNextStep} />}
						{step === 'mode' && (
							<Mode
								card={card}
								onPrevious={handlePrevStep}
								onSubmit={handleNextStep}
							/>
						)}
						{step === 'customise' && (
							<Customise
								card={card}
								mode={values.mode}
								onPrevious={handlePrevStep}
								onSubmit={handleNextStep}
							/>
						)}
						{step === 'complete' && <Complete card={card} />}
					</Box>
				);
			}}
		</Formik>
	);
};

const SetupRouter = () => {
	return (
		<Layout>
			<Router basepath="/setup">
				<Setup path="/:id" />
				{/*<Error404 default />*/}
			</Router>
		</Layout>
	);
};

export default SetupRouter;
