import React, { useEffect, useState } from 'react';
import styles from './styles';
import { useTheme } from '@mui/material';
import { Card, CardActionArea, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import LinkHubIcon from '@assets/modes/link-hub.inline.svg';
import { CardModes } from '@models/card';
import VCardIcon from '@assets/modes/v-card.inline.svg';
import ExternalUrlIcon from '@assets/modes/external-url.inline.svg';

const CardSelectMode: React.FC<{ mode: CardModes; onClick?: () => void }> = ({ mode, onClick }) => {
	const theme = useTheme();
	const cardOptions = [
		{
			id: 'linkHub',
			title: 'Link Hub',
			description: 'Display a list of customisable links',
			icon: <LinkHubIcon style={{ height: 46 }} />,
		},
		{
			id: 'vCard',
			title: 'Contact Card',
			description: 'A downloadable .vCard',
			icon: <VCardIcon style={{ height: 46 }} />,
		},
		{
			id: 'externalUrl',
			title: 'External Link',
			description: 'Redirect people to an external URL',
			icon: <ExternalUrlIcon style={{ height: 46 }} />,
		},
	];

	const selectedOptions = cardOptions.find(({ id }) => id === mode);
	if (!selectedOptions?.id) return <div>Card option doesn't exist</div>;
	return (
		<div css={styles(theme)}>
			<Card sx={{ height: 160 }} onClick={onClick}>
				<CardActionArea sx={{ height: '100%', p: 2 }}>
					<Stack sx={{ height: '100%' }} spacing={2} justifyContent="space-between">
						<Typography variant="caption" component="h3">
							{selectedOptions?.title}
						</Typography>
						{selectedOptions?.icon}

						<Typography variant="subtitle1" component="p">
							{selectedOptions?.description}
						</Typography>
					</Stack>
				</CardActionArea>
			</Card>
		</div>
	);
};

export default CardSelectMode;
