import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Card as CardType } from '@models/card';
import Loader from '@components/common/Loader';
import { useSnackbar } from '@components/common/Snackbar';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import Links from '@components/Dashboard/LinkHubs/LinkHub/Editor/Links';
import { v4 as uuidv4 } from 'uuid';
import useLinkHubAppearance from '@helpers/hooks/useLinkHubAppearance';
import { SetupCustomiseInitialValues } from '@views/Setup/Customise';

const LinkHub: React.FC<{ card: CardType }> = ({ card }) => {
	const user = useSelector(({ user }) => user);
	const initialLinkHub = {
		title: `${user?.firstName}'s Link Hub`,
		links: [
			{
				_id: uuidv4(),
				name: '',
				url: '',
				active: true,
			},
		],
		appearance: useLinkHubAppearance(),
		avatar: 'google.com',
	};
	const [loading, setLoading] = useState<boolean>(true);
	const [openSnackbar] = useSnackbar();
	const { values, setFieldValue } = useFormikContext<SetupCustomiseInitialValues>();
	const linkHub = values?.linkHub;

	useEffect(() => {
		(async () => {
			try {
				// NOTE: Attempt to see if user has any link hubs yet, then let them choose

				// const { data: newLinkHub } = await makePostRequest(LINK_HUBS, {
				// 	title: `${user?.firstName}'s Link Hub`,
				// 	...values.linkHub,
				// });
				//
				// setLinkHub(newLinkHub);

				// Otherwise, set them a new link hub immediately
				setFieldValue('linkHub', initialLinkHub);
				setLoading(false);
			} catch (error) {
				if (error === 'cancelled') return;
				openSnackbar(
					error?.errorMessage ?? 'An error occurred attempting to create a Link Hub.',
					'error'
				);
				navigate('?step=mode');
			}
		})();
	}, []);

	if (loading || !linkHub) return <Loader />;

	return <Links path="linkHub.links" save={false} />;
};

export default LinkHub;
