import { css } from '@emotion/react';

export default (theme) => css`
	display: flex;
	flex-direction: column;
	background-color: ${theme.palette.primary.main};
	height: auto;

	.logo {
		width: ${theme.spacing(6)};
	}

	main {
		flex-grow: 1;
		height: 100%;

		> *:first-of-type {
			height: 100%;
			display: flex;
			flex-direction: column;
		}
	}

	.container {
		height: 100%;
	}
`;
