import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { RouteComponentProps, useLocation } from '@reach/router';
import { useSnackbar } from '@components/common/Snackbar';
import { Card } from '@models/card';
import { makeGetRequest, makePutRequest } from '@helpers/requests';
import { CARD, USER_PROFILE_PUBLIC } from '@helpers/api';
import {
	Box,
	Button,
	CircularProgress,
	Fade,
	FormControl,
	Grid,
	Grow,
	MenuItem,
} from '@mui/material';
import { Field, Form, Formik, useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';
import { Select, TextField } from 'formik-mui';
import { User } from '@models/user';
import * as Yup from 'yup';
import styles from '@views/Setup/Welcome/styles';
import Loader from '@components/common/Loader';
import { SetupCardNameValidation, SetupInitialValues } from '@views/Setup';
import { CheckoutCardColourValidation } from '@views/Checkout';
import useDidUpdateEffect from '@helpers/hooks/useDidUpdateEffect';
import { CheckoutCardColour } from '@models/checkout';
import LogoWhite from '@assets/logo/logo-white.inline.svg';

const Name: React.FC<{ card: Card; onSubmit: (values: SetupInitialValues) => void }> = ({
	card,
	onSubmit,
}) => {
	const theme = useTheme();
	const location: any = useLocation();
	const [openSnackbar] = useSnackbar();
	const [show, setShow] = useState(true);
	const { values } = useFormikContext<SetupInitialValues>();

	const handleSubmit = (values) => {
		setShow(false);
		setTimeout(() => onSubmit(values), 500);
	};

	const initialValues: { name: string } = {
		name: values?.name || `${card?.detail?.lineOne}'s Card`,
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				name: SetupCardNameValidation,
			})}
			onSubmit={handleSubmit}
		>
			{({ handleSubmit, setFieldValue, values, touched, errors }) => {
				return (
					<Grid
						css={styles(theme)}
						container
						direction="column"
						sx={{ py: { xs: 4, md: 8 } }}
					>
						<Grid item xs="auto">
							<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
								<div>
									<LogoWhite style={{ width: 56 }} />
									<Box mb={5} />
								</div>
							</Fade>
						</Grid>
						<Grid item xs sx={{ width: '100%' }}>
							<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
								<Grid container sx={{ minHeight: '100%' }} alignItems="center">
									<Grid item sx={{ width: '100%' }}>
										<Typography variant="h2">
											Give your card a nickname.
										</Typography>

										<Field
											component={TextField}
											name="name"
											label="Name"
											fullWidth
											margin="normal"
											variant="filled"
											helperText=""
											FormHelperTextProps={{}}
										/>
									</Grid>
								</Grid>
							</Fade>
						</Grid>
						<Grid item xs="auto" sx={{ width: '100%' }}>
							<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
								<Button fullWidth variant="contained" onClick={() => handleSubmit()}>
									Confirm
								</Button>
							</Fade>
						</Grid>
					</Grid>
				);
			}}
		</Formik>
	);
};

export default Name;
