import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { Card as CardType, CardModes } from '@models/card';
import { Box, Fade, Grid, IconButton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import styles from '@views/Setup/Welcome/styles';
import { SetupCardModeValidation, SetupInitialValues } from '@views/Setup';
import { ArrowBack } from '@mui/icons-material';
import LogoWhite from '@assets/logo/logo-white.inline.svg';
import CardSelectMode from '@components/CardSelectMode';
import { Formik } from 'formik';

const Mode: React.FC<{
	card: CardType;
	onPrevious: () => void;
	onSubmit: (values: SetupInitialValues) => void;
}> = ({ card, onPrevious, onSubmit }) => {
	const theme = useTheme();
	const [show, setShow] = useState(true);

	const handleSubmit = (values) => {
		setShow(false);
		setTimeout(() => onSubmit(values), 500);
	};

	const initialValues: { mode: CardModes | '' } = {
		mode: '',
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				mode: SetupCardModeValidation,
			})}
			onSubmit={handleSubmit}
		>
			{({ handleSubmit, setFieldValue, values, touched, errors }) => {
				useEffect(() => {
					if (!!values.mode) handleSubmit();
				}, [values.mode]);

				return (
					<Grid
						css={styles(theme)}
						container
						direction="column"
						wrap="nowrap"
						sx={{ py: { xs: 4, md: 8 } }}
					>
						<Grid item xs="auto">
							<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
								<div>
									<Box sx={{ display: 'inline-flex' }}>
										<Typography
											variant="h6"
											sx={{ display: 'flex', alignItems: 'center' }}
										>
											<LogoWhite style={{ width: 56 }} />
											<span className="setup-logo">Wave card</span>
										</Typography>
									</Box>

									<Box mb={5} />
								</div>
							</Fade>
						</Grid>
						<Grid item xs sx={{ width: '100%' }}>
							<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
								<Grid
									container
									sx={{ height: '100%', width: '100%' }}
									alignItems="center"
								>
									<Grid item sx={{ width: '100%' }}>
										<Stack
											spacing={1}
											sx={{ mb: 3 }}
											direction="row"
											alignItems="center"
										>
											<IconButton size="small" onClick={onPrevious}>
												<ArrowBack />
											</IconButton>
											<Stack>
												<Typography variant="h2">
													What mode suits you?
												</Typography>
												<Typography variant="subtitle2">
													Don't worry, you can always change this later
													on.
												</Typography>
											</Stack>
										</Stack>

										<Stack spacing={3}>
											<CardSelectMode
												mode="linkHub"
												onClick={() => setFieldValue('mode', 'linkHub')}
											/>
											<CardSelectMode
												mode="vCard"
												onClick={() => setFieldValue('mode', 'vCard')}
											/>
											<CardSelectMode
												mode="externalUrl"
												onClick={() => setFieldValue('mode', 'externalUrl')}
											/>
										</Stack>
									</Grid>
								</Grid>
							</Fade>
						</Grid>
					</Grid>
				);
			}}
		</Formik>
	);
};

export default Mode;
