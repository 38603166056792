import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { RouteComponentProps, useLocation } from '@reach/router';
import { useSnackbar } from '@components/common/Snackbar';
import { Card } from '@models/card';
import { makeGetRequest, makePutRequest } from '@helpers/requests';
import { CARD, USER_AVATAR, USER_PROFILE_PUBLIC } from '@helpers/api';
import {
	Avatar,
	Box,
	Button,
	CircularProgress,
	Fade,
	FormControl,
	Grid,
	Grow,
	MenuItem,
} from '@mui/material';
import { Field, Form, Formik, useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';
import { Select, TextField } from 'formik-mui';
import { User } from '@models/user';
import * as Yup from 'yup';
import styles from '@views/Setup/Welcome/styles';
import Loader from '@components/common/Loader';
import { SetupInitialValues } from '@views/Setup';
import LogoWhite from '@assets/logo/logo-white.inline.svg';

const Welcome: React.FC<{ card: Card; onContinue: () => void }> = ({ card, onContinue }) => {
	const theme = useTheme();
	const location: any = useLocation();
	const [openSnackbar] = useSnackbar();
	const [show, setShow] = useState(true);
	const { values } = useFormikContext<SetupInitialValues>();

	const handleSubmit = () => {
		setShow(false);
		setTimeout(() => onContinue(), 500);
	};

	return (
		<Grid
			css={styles(theme)}
			container
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{ py: { xs: 4, md: 8 } }}
		>
			<Grid item xs>
				<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
					<Grid
						container
						sx={{ height: '100%' }}
						justifyContent="center"
						alignItems="center"
						direction="column"
						spacing={3}
					>
						<Grid item>
							<Avatar
								sx={{ width: theme.spacing(10), height: theme.spacing(10) }}
								src={USER_AVATAR(card?.user?._id)}
								alt={`${
									!!card?.user?.firstName ? `${card?.user?.firstName}'s ` : ''
								}Avatar`}
							/>
						</Grid>
						<Grid item>
							<Grid
								container
								direction="column"
								justifyContent="center"
								alignItems="center"
								spacing={1}
							>
								<Grid item>
									<Typography variant="h1">
										Hello
										{!!card?.user?.firstName ? ` ${card?.user?.firstName}` : ''}
									</Typography>
								</Grid>
								<Grid item>
									<Typography variant="h2">Welcome to Wave Card</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Box mt={3} />
							<LogoWhite style={{ width: 56 }} />
						</Grid>
					</Grid>
				</Fade>
			</Grid>
			<Grid item xs="auto" sx={{ width: '100%' }}>
				<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
					<Button fullWidth variant="inversed" onClick={handleSubmit}>
						Setup my card
					</Button>
				</Fade>
			</Grid>
		</Grid>
	);
};

export default Welcome;
