import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import { useLocation } from '@reach/router';
import { useSnackbar } from '@components/common/Snackbar';
import { Card as CardType, CardModes } from '@models/card';
import {
	Box,
	Button,
	Card,
	CardActionArea,
	Fade,
	Grid,
	IconButton,
	Stack,
} from '@mui/material';
import { Field, Formik, useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import styles from '@views/Setup/Welcome/styles';
import {
	SetupCardExternalUrlValidation,
	SetupCardModeValidation,
	SetupInitialValues,
} from '@views/Setup';
import LinkHubIcon from '@assets/modes/link-hub.inline.svg';
import VCardIcon from '@assets/modes/v-card.inline.svg';
import ExternalUrlIcon from '@assets/modes/external-url.inline.svg';
import { ArrowBack } from '@mui/icons-material';
import { TextField } from 'formik-mui';
import { ExternalUrl } from '@models/externalUrl';
import { VCard } from '@models/vCard';
import { LinkHub } from '@models/linkHub';
import Loader from '@components/common/Loader';
import { validateUrl } from '@helpers/common';
import { Link } from 'gatsby';
import LogoWhite from '@assets/logo/logo-white.inline.svg';
import CelebrationIcon from '@assets/misc/celebration.inline.svg';

const Complete: React.FC<{
	card: CardType;
}> = ({ card }) => {
	const theme = useTheme();
	const [show, setShow] = useState(true);
	const { values } = useFormikContext<SetupInitialValues>();

	if (values.mode === 'setup')
		return (
			<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
				<Loader />
			</Fade>
		);

	return (
		<Grid css={styles(theme)} container direction="column" sx={{ py: { xs: 4, md: 8 } }}>
			<Grid item xs="auto">
				<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
					<div>
						<LogoWhite style={{ width: 56 }} />
						<Box mb={5} />
					</div>
				</Fade>
			</Grid>
			<Grid item xs sx={{ width: '100%' }}>
				<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
					<Grid container sx={{ height: '100%', width: '100%' }} alignItems="center">
						<Grid item sx={{ width: '100%' }}>
							<Typography variant="h2">Congratulations</Typography>
							<Typography variant="h1">Your card is now setup.</Typography>
							<Box sx={{ mt: { xs: 5, md: 8, lg: 12 } }} />
							<Grid container sx={{ px: 12 }} justifyContent="center">
								<Grid item>
									<CelebrationIcon style={{ width: '100%', maxWidth: 200 }} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Fade>
			</Grid>
			<Grid item xs="auto" sx={{ width: '100%' }}>
				<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
					<Button
						fullWidth
						variant="contained"
						component={Link}
						to={`/dashboard/cards/${card?._id}`}
					>
						View my card
					</Button>
				</Fade>
			</Grid>
		</Grid>
	);
};

export default Complete;
