import React, { useEffect, useRef, useState } from 'react';
import { Global } from '@emotion/react';
import globalStyles from '@globalStyles';
import styles from './styles';
import { useTheme } from '@mui/styles';
import LogoWhite from '@assets/logo/logo-white.inline.svg';
import { Box, Container } from '@mui/material';
import { useMatch } from '@reach/router';

const Setup: React.FC<{
	inversedHeader?: boolean;
	disableGutterTop?: boolean;
	disableGutterBottom?: boolean;
	flush?: boolean;
}> = ({ children, inversedHeader, flush, disableGutterTop, disableGutterBottom }) => {
	const theme = useTheme();
	const isWelcome = useMatch('/setup/:id');

	return (
		<>
			<Global styles={globalStyles(theme)} />
			<Box css={styles(theme)}>
				<Container
					maxWidth="md"
					className="container"
					sx={{ display: 'flex', px: { xs: 4, md: 8 } }}
				>
					{!isWelcome && <LogoWhite className="logo" />}
					<Box component="main">{children}</Box>
				</Container>
			</Box>
		</>
	);
};

export default Setup;
