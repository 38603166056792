import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { useLocation } from '@reach/router';
import { useSnackbar } from '@components/common/Snackbar';
import { Card as CardType, CardModes } from '@models/card';
import { Box, Button, Card, CardActionArea, Fade, Grid, IconButton, Stack } from '@mui/material';
import { Field, Formik, useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import styles from '@views/Setup/Welcome/styles';
import {
	SetupCardExternalUrlValidation,
	SetupCardLinkHubValidation,
	SetupCardModeValidation,
	SetupCardVCardValidation,
	SetupInitialValues,
} from '@views/Setup';
import { ArrowBack } from '@mui/icons-material';
import { TextField } from 'formik-mui';
import Loader from '@components/common/Loader';
import { validateUrl } from '@helpers/common';
import LogoWhite from '@assets/logo/logo-white.inline.svg';
import LinkHub from '@views/Setup/Customise/LinkHub';
import { useSelector } from 'react-redux';
import useLinkHubAppearance from '@helpers/hooks/useLinkHubAppearance';
import { makePostRequest, makePutRequest } from '@helpers/requests';
import { CARD, EXTERNAL_URLS, LINK_HUBS, VCARDS } from '@helpers/api';
import { ExternalUrl } from '@models/externalUrl';
import { VCardValues } from '@models/vCard';
import { LinkHubValues } from '@models/linkHub';

export interface SetupCustomiseInitialValues {
	mode: CardModes | '';
	externalUrl: ExternalUrl | null;
	vCard: VCardValues | null;
	linkHub: LinkHubValues | null;
}

const Customise: React.FC<{
	card: CardType;
	mode: Omit<CardModes, 'setup'>;
	onPrevious: () => void;
	onSubmit: (values: SetupInitialValues) => void;
}> = ({ card, mode, onPrevious, onSubmit }) => {
	const theme = useTheme();
	const [show, setShow] = useState(true);
	const { values } = useFormikContext<SetupInitialValues>();
	const user = useSelector(({ user }) => user);

	const handleSubmit = (values) => {
		setShow(false);
		setTimeout(() => {
			(async () => {
				try {
					console.log('handleSetupSave', { values });
					let linkHubId;
					let externalUrlId;
					let vCardId;

					if (values.mode === 'externalUrl') {
						const { data: newExternalUrl } = await makePostRequest(
							EXTERNAL_URLS,
							values.linkHub
						);
						externalUrlId = newExternalUrl?._id;
					}

					if (values.mode === 'linkHub') {
						const { data: newLinkHub } = await makePostRequest(LINK_HUBS, {
							...values.linkHub,
							links: values.linkHub.links?.map((link) => ({
								...link,
								_id: undefined,
							})),
						});
						linkHubId = newLinkHub?._id;
					}

					if (values.mode === 'vCard') {
						const { data: newVCard } = await makePostRequest(VCARDS, values.vCard);
						linkHubId = newVCard?._id;
					}

					await onSubmit?.({
						...values,
						linkHubId,
						externalUrlId,
						vCardId,
					});
				} catch (error) {
					setShow(true);
				}
			})();
		}, 500);
	};

	const initialValues: SetupCustomiseInitialValues = {
		mode: values.mode,
		externalUrl: null,
		vCard: null,
		linkHub: null,
	};

	if (values.mode === 'setup') return <Loader />;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				mode: SetupCardModeValidation,
				externalUrl: SetupCardExternalUrlValidation,
				vCard: SetupCardVCardValidation,
				linkHub: SetupCardLinkHubValidation,
			})}
			onSubmit={handleSubmit}
		>
			{({ handleSubmit, values }) => {
				const { mode } = values;

				console.log({ values });

				return (
					<Grid
						css={styles(theme)}
						container
						direction="column"
						sx={{ py: { xs: 4, md: 8 } }}
					>
						<Grid item xs="auto">
							<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
								<div>
									<Box sx={{ display: 'inline-flex' }}>
										<Typography
											variant="h6"
											sx={{ display: 'flex', alignItems: 'center' }}
										>
											<LogoWhite style={{ width: 56 }} />
											<span className="setup-logo">Wave card</span>
										</Typography>
									</Box>
									<Box mb={5} />
								</div>
							</Fade>
						</Grid>
						<Grid item xs sx={{ width: '100%', minHeight: 0, display: 'flex' }}>
							<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
								<Grid
									container
									sx={{ height: '100%', width: '100%' }}
									alignItems="center"
								>
									<Grid
										item
										sx={{
											width: '100%',
											height: values.mode === 'linkHub' ? '100%' : 'auto',
											minHeight: 0,
											flexGrow: 1,
											display: 'flex',
										}}
									>
										{values.mode === 'externalUrl' && (
											<>
												<Stack
													spacing={1}
													sx={{ mb: 3 }}
													direction="row"
													alignItems="center"
												>
													<IconButton size="small" onClick={onPrevious}>
														<ArrowBack />
													</IconButton>
													<Typography variant="h2">
														What URL would you like your card to
														redirect to?
													</Typography>
												</Stack>

												<Field
													component={TextField}
													name="externalUrl.url"
													label="External URL"
													fullWidth
													variant="filled"
													helperText={
														!validateUrl(values.externalUrl?.url)
															? `Make sure to include the protocol and domain extension (e.g. "https://" and ".com")`
															: null
													}
												/>
											</>
										)}

										{values.mode === 'vCard' && (
											<Grid
												container
												sx={{
													height: '100%',
													width: '100%',
													display: 'flex',
													flexDirection: 'column',
												}}
												alignItems="center"
											>
												<Grid item sx={{ width: '100%' }}>
													<Typography>vCard setup</Typography>
												</Grid>
											</Grid>
										)}

										{values.mode === 'linkHub' && <LinkHub card={card} />}
									</Grid>
								</Grid>
							</Fade>
						</Grid>
						<Grid item xs="auto" sx={{ width: '100%' }}>
							<Fade in={show} timeout={{ enter: 750, exit: 350 }}>
								<Button
									fullWidth
									variant="contained"
									onClick={() => handleSubmit()}
								>
									<>
										{mode === 'externalUrl' && 'Complete'}
										{mode === 'vCard' && 'Complete'}
										{mode === 'linkHub' && 'Complete'}
									</>
								</Button>
							</Fade>
						</Grid>
					</Grid>
				);
			}}
		</Formik>
	);
};

export default Customise;
