import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	background: ${theme.palette.primary.main};

	.setup-logo {
		margin-left: ${theme.spacing(2)};
		text-transform: uppercase;
		font-weight: ${theme.typography.fontWeightBold};
		letter-spacing: 0.12rem;
		color: ${theme.palette.common.white};
	}
`;
